<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>      
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs> 
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner"  :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">

        <!--------------------generate key and create organization headline start--------->
        <div class="pb-4" v-show="showgeneratekey">
          <div class="d-flex justify-space-between align-center">
            <div>
              <h2 class="text-center font-weigt-bold">User Subscription</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
            </div>
          <div class=" d-flex flex-md-row flex-sm-column justify-end align-md-end justify-md-space-between">
              <div>
                <v-btn text class="tertiary2--text nocap py-5" @click.stop="generateKey()">
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-check-decagram-outline</v-icon>
                    Generate Key and Create Organization
                </v-btn>
                <!-- <v-btn text
                  @click.stop="deletesubscriptions = true"
                  :disabled="!selectedSubscription.length"
                  class="error--text nocap py-5"
                >
                  <v-icon class="pr-1 add">mdi-delete-circle </v-icon>
                  Delete
                </v-btn> -->
                <v-btn text         
                  @click="showSubscriptionPlan()"  
                  class="tertiary2--text nocap py-5"
                >
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-format-list-bulleted</v-icon>
                  Show Subscription Plan
                </v-btn>
                </div>
                </div>
            </div>
        </div>
        <!------------------------------------------ end ------------------------------------------------>

        <!--------------------------------------- subscription plan start-------------------------------->

        <div class="pb-4" v-show="showsubscriptionplan" >
          <div class="d-flex justify-space-between align-center">
            <div>
              <h2 class="text-center font-weigt-bold">Subscription</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
            </div>
          <div class=" d-flex flex-md-row flex-sm-column justify-end align-md-end justify-md-space-between">
              <div>
                <v-btn text class="tertiary2--text nocap py-5" @click.stop="addSubscriptionDialog()">
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-checkbox-multiple-marked-circle-outline </v-icon>
                    Add Subscription
                </v-btn>
                <v-btn text
                  @click.stop="deletesubscriptions = true"
                  :disabled="!selectedSubscription.length"
                  class="error--text nocap py-5"
                >
                  <v-icon class="pr-1 add">mdi-delete-circle </v-icon>
                  Delete
                </v-btn>
                <v-btn text         
                  @click="showGenerateKey()"  
                  class="tertiary2--text nocap py-5"
                >
                  <v-icon class="pr-1 add" style="font-size:26px">mdi-format-list-bulleted</v-icon>
                  Show User Subscription
                </v-btn>
                </div>
                </div>
            </div>
        </div>
        <div>
          <v-data-table
            :mobile-breakpoint="0"
            v-model="selectedUserSubscription"
            v-show="showgeneratekey"
            :headers="usersubscriptionheaders"
            :items="generatekeydata"
            item-key="id"
            item.value=""
            show-select
            
            class="elevation-1 listingTable"
          >
          <template v-slot:item.activation_key="{ item }">
            <div v-if="item.activation_key" class="truncate-text" :title="item.activation_key">
              {{ item.activation_key.slice(0, 30) }}...
            </div>
          </template>
          </v-data-table>
        </div>
        <div>
          <v-data-table
            :mobile-breakpoint="0"
            v-model="selectedSubscription"
            v-show="showsubscriptionplan"
            :headers="subscriptionheaders"
            :items="subscriptiondata"
            item-key="id"
            item.value=""
            show-select
            @click:row="viewSubscriptionDetails"
            class="elevation-1 listingTable"
          >
          <template v-slot:item.description="{ item }">
            <div class="truncate-text" :title="item.description">
              {{ item.description.slice(0, 30) }}...
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <div class="truncate-text" :title="item.details">
              {{ item.details.slice(0, 30) }}...
            </div>
          </template>
          </v-data-table>
        </div>
        <!--------------------------Add subscription start------------------------------------------>

        <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addsubscriptions"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">Add Subscription</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearSubscriptionObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <addSubscriptionInfo
                        :subscriptionitem="subscriptionitem"
                        :isValidForm="isValidForm"
                    />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearSubscriptionObj()"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="Addsubscription()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
        <!-------------------------------------end--------------------------------------------------->

        <!-- --------------------------Generate Subscription start ---------------------------------------->
        <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="generateTokenOrg"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-overlay absolute v-if="isLoading" opacity="0" z-index="9999">
                    <v-container fill-height>
                      <v-row justify="center" align="center">
                        <v-progress-circular
                          indeterminate
                          size="40" 
                          color="primary"
                          width="4"
                          style="stroke-dasharray: 4, 4"
                        ></v-progress-circular>
                      </v-row>
                    </v-container>
                  </v-overlay>
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">Create Organization and Assign Token</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearSubscriptionObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div>
                      <generateToken
                        :ownerList="ownerList"
                        :generatetokendata="generatetokendata"
                        :planduration="planduration"
                        :isValidForm="isValidForm"
                      />
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearSubscriptionObj()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="AddGenerateKey()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>

        <!-- --------------------------End ---------------------------------------->
        <!-- --------------------------Success pop up ---------------------------------------->
        <v-row justify="center">
            <v-dialog
              
              style="box-shadow: none; background-color: transparent"
              v-model="successKeyGenerate"
              persistent
              max-width="600px"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="150"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 350px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div v-if="response_msg.length" class="mt-4">{{response_msg}}</div>
                  </v-card-title>
                  <p class="text-body-1 mt-3">
                    ✅ Your token has been generated successfully. <br />
                    🏢 Organisation has been created successfully. <br />
                    🔑 <strong>Please keep your secret key safe!</strong>
                  </p>
                  <v-text-field
                    v-if="response_message.length"
                    v-model="response_message"
                    readonly
                    dense
                    outlined
                    class="mt-3"
                    label="Secret Key"
                    append-icon="mdi-content-copy"
                    v-on:click:append="copyApikey"
                  ></v-text-field>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successKeyGenerate = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!--------------------------------------------------- End ----------------------------->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successMessage"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div v-if="response_msg.length" class="mt-4">{{response_msg}}</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{response_message}}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successMessage = false">Okay </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        <!-------------------------------------start delete popup--------------------------------->
        <v-row justify="center">
          <v-dialog
            class="msPop"
            style="box-shadow: none; background-color: transparent"
            v-model="errorMessage"
            persistent
            max-width="388"
            content-class="vDialog"
          >
            <v-card
              class="d-flex flex-column pa-0 align-center justify-center"
              flat
              style="background-color: transparent"
            >
              <v-card-title
                class="text-h5 text-bold d-flex text-center pa-0"
                style="margin-bottom: -19px"
              >
                <v-img
                  style="z-index: 1"
                  alt="error icon"
                  class="d-flex align-center justify-center"
                  src="@/assets/error-mark1.svg"
                  contain
                  max-width="192"
                ></v-img>
              </v-card-title>
              <v-card-text
                class="text-center white rounded-lg pa-4"
                style="height: 220px"
              >
                <v-card-title class="text-h5 text-bold d-flex justify-center text-red">
                  <div v-if="response_msg.length" class="mt-4">{{response_msg}}</div>
                </v-card-title>
                <p v-if="response_message.length">{{response_message}}</p>
                <v-card-actions>
                  <div class="text-center mt-4">
                    <v-btn class="nocap" color="red darken-2" @click="errorMessage = false">
                      Okay
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletesubscriptions"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected function?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletesubscriptions = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deleteSubscription()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>

        <!-- -------++++ Function Detail Pages +++++--------->
        <v-dialog v-model="detailsubscription" width="1000" scrollable>
            <detailSubscription
              @closeDetail="closeSubscriptionDetails"
              :rowItem="selectedsubscription"
              @details="detailSubscriptions"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit function +++++--------->
          <v-dialog
            v-model="editsubscription"
            width="1000"
            scrollable
            class="d-none"
            persistent
          >
            <editSubscription 
              :subscriptionitem ="selectedsubscription" 
              @closeSubscriptionUpdate="closeSubscriptionUpdate"
              @saveData="updateSubscription"
              :response_status="response_status" 
            />
          </v-dialog>
          <v-dialog v-model="detailusersubscription" width="1000" scrollable>
            <detailUserSubscription
              @closeDetail="closeUserSubscriptionDetails"
              :rowItem="selectedusersubscription"
              @detailUserSubscriptions="detailUserSubscriptions"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit function +++++--------->
          <v-dialog
            v-model="editusersubscription"
            width="1000"
            scrollable
            class="d-none"
            persistent
          >
            <editUserSubscription 
              :usersubscriptionitem="selectedusersubscription" 
              @closeUserSubscriptionUpdate="closeUserSubscriptionUpdate"
              @saveData="updateUserSubscription"
              :ownerList="ownerList"
            />
          </v-dialog>
          
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import API_Util from "../services/util/API_Util.js";
import addSubscriptionInfo from "./addSubscriptionInfo.vue";
import editSubscription from "./editSubscription.vue";
import detailSubscription from "./detailSubscription.vue";
import editUserSubscription from "./editUserSubscription.vue";
import detailUserSubscription from "./detailUserSubscription.vue";
import generateToken from "./generateToken.vue";
export default {
  components:{
    addSubscriptionInfo,
    editSubscription,
    detailSubscription,
    editUserSubscription,
    generateToken,
    detailUserSubscription
  },
  name:"subscription",
  data: () => ({
    subscriptiondata:[],
    planduration:[],
    generatekeydata:[],
    apiKey: "",
    subscriptionheaders : [],
    usersubscriptionheaders : [],
    ownerDrop : "",
    selectedFun: [],
    selectedOwner : "",
    ownerDesc : "",
    isLoading : false,
    snackbar: true,
    selectedSubscription: {},
    selectedUserSubscription: {},
    showsubscriptionplan: false,
    showgeneratekey: true,
    addsubscriptions : false,
    generateTokenOrg: false,
    isValidForm : false,
    detailsubscription : false,
    detailusersubscription : false,
    editsubscription : false,
    editusersubscription : false,
    deletesubscriptions : false,
    selectedsubscription: {},
    selectedusersubscription: {},
    response_msg : "",
    ownerList: [],
    response_message: "",
    successKeyGenerate: false,
    termsAccepted: false,
    response_status : "",
    successMessage: false,
    errorMessage: false,
    apiTooltip: false,
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Subscription",
        disabled: true,
        href: "",
      },
    ],
    decode_token_data: {},
    generatetokendata: {
      owner: "",
      org_name: "",
      email: "",
      plan_duration:"1 Year",
      plan_type: "Named Users",
      file_size: 0,
      no_of_users: 0,
      no_of_conversation: 0,
    },
    subscriptionitem: {
      id: 0,
      plan_name: "",
      no_of_users: 0,
      file_size: 0,
      price: "",
      description : "",
      details : ""
    },
    usersubscriptionitem: {
      id: 0,
      owner: 0,
      org: 0,
      plan_id: "",
      plan_name: "",
      token_type: "",
      modifier: "",
      activation_key: "",
    },

  }),
  created(){
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerType = localStorage.getItem("ownerType");
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getOwnerList();
    this.getSubscription();
    this.getSubscriptionHeaders();
    this.getUserSubscriptionHeaders();
    this.userSubscriptionList();
  },
  methods:{
    clearSubscriptionObj(){
      this.addsubscriptions = false;
      this.generateTokenOrg = false;
    },
    clearSubscriptionData() {
      this.subscriptionitem = {
        id: 0,
        plan_name:"",
        no_of_users:0,
        file_size:0,
        price:"",
        description : "",
        details : ""
      }
    },
    clearGenerateTokenData() {
      this.generatetokendata = {
      owner: this.ownerList[0]["id"],
      org_name: "",
      email: "",
      plan_duration: "1 Year",
      plan_type :"Named Users",
      file_size: 0,
      no_of_users: 0,
      no_of_conversation:0
      }
    },
    clearSubscriptionTokenData() {
      this.subscriptiontokendata = {
        activation_key:"",
      }
    },
    clearUserSubscriptionData() {
      this.usersubscriptionitem = {
        id: 0,
        owner: 0,
        org: 0,
        plan_id: "",
        plan_name: "",
        plan_type : "",
        token_type: "",
        modifier: "",
        activation_key: "",
      }
    },
    apiTooltipCopy() {     
       this.apiTooltip = true;
      setTimeout(() => {
        this.apiTooltip = false;
      }, 2000);
    },
    copyApikey(){
      navigator.clipboard.writeText(this.response_message);
      console.log(this.response_message)
    },
    getOwnerList() {
        var empt = {}
        API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => {
          this.ownerList = response.data.results;
          console.log(this.ownerList)
          this.generatetokendata.owner = this.ownerList[0]["id"]
        });
        
    },
    addSubscriptionDialog(){
      this.addsubscriptions = true;
      this.isValidForm = true;
      this.clearSubscriptionData();
    },
    generateKey(){
      this.generateTokenOrg = true;
      this.isValidForm = true;
      this.clearGenerateTokenData();
    },
    showSubscriptionPlan(){
      this.showgeneratekey = false;
      this.showsubscriptionplan = true;
    },
    showGenerateKey(){
      this.showsubscriptionplan = false;
      this.showgeneratekey = true;
    },
    refreshSubscriptionData() {
      this.subscriptionitem.plan_name = "";
      this.subscriptionitem.no_of_users = 0;
      this.subscriptionitem.file_size = 0;
      this.subscriptionitem.price = "";
      this.subscriptionitem.description = "";
      this.subscriptionitem.details = "";
    },
    refreshUserSubscriptionData() {
      this.usersubscriptionitem.owner = "";
      this.usersubscriptionitem.org = "";
      this.usersubscriptionitem.plan_id = "";
      this.usersubscriptionitem.plan_name = "";
      this.usersubscriptionitem.plan_type = "";
      this.usersubscriptionitem.token_type = 0;
      this.usersubscriptionitem.modifier = 0;
      this.usersubscriptionitem.activation_key = ""; 
    },
    closeSubscriptionDetails() {
      this.detailsubscription = false;
      this.editsubscription = true;
    },
    closeUserSubscriptionDetails() {
      this.detailusersubscription = false;
      this.editusersubscription = true;
    },
    closeSubscriptionUpdate(status) {
      this.editsubscription = status;
    },
    closeUserSubscriptionUpdate(status) {
      this.editusersubscription = status;
    },
    updateTermsAccepted(value) {
      this.termsAccepted = value;
    },
    verifyTokens(activationKey){
      var data={ "owner" :localStorage.getItem('owner'), "activation_key": activationKey}
      API_Util.URLS.axios
      .post(API_Util.URLS.verifytoken, data, {
        headers: { "x-api-key": this.apiKey },
        params: {},
      })
      .then((response) => {
          console.log("reponse", response.data)
          var resp=response.data
          if (resp.status == "Success"){
              this.decode_token_data = response.data.results;
          }
      });
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem('ownerType', obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getSubscription();
    },
    getSubscriptionHeaders(){

      this.subscriptionheaders = [

        { text:"PLAN_NAME", align: "start", sortable: true, value: "plan_name",width: "15%"},
        { text: "PRICE", value: "price",width: "10%" },
        { text: "DESCRIPTION", value: "description",width: "30%" },
        { text: "DETAILS", value: "details" ,width: "30%"},
        { text: "DATE_CREATED", value: "date_created",width: "15%" },
      ]
    },
    getUserSubscriptionHeaders(){

      this.usersubscriptionheaders = [
        { text:"OWNER_NAME", align: "start", sortable: true, value: "owner_name",width: "15%"},
        { text: "ORG_NAME", value: "org_name",width: "15%" },
        { text: "PLAN_DURATION", value: "plan_duration",width: "15%" },
        { text:"PLAN_TYPE", value: "plan_type",width: "15%"},
        { text:"ACTIVATION_KEY", align: "start", sortable: true, value: "activation_key",width: "25%"},
        { text: "DATE_CREATED", value: "date_created",width: "15%" },
      ]
    },
    detailSubscriptions(value) {
      this.detailsubscription = value;
    },
    detailUserSubscriptions(value) {
      this.detailusersubscription = value;
    },
    getSubscription() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.getsubscription , {}, { headers })
        .then((response) => {
          console.log(response)
          this.subscriptiondata = response.data.data.all;
          this.planduration = response.data.data.planduration;
          
          console.log(this.subscriptiondata)
          this.loader = false;
        });
    },
    userSubscriptionList() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.usersubscriptionlist , {}, { headers })
        .then((response) => {
          console.log(response)
          this.generatekeydata = response.data.data.all;
          console.log(this.generatekeydata)
          this.loader = false;
        });
    },
    AddGenerateKey(){
        if(this.generatetokendata.owner == ""){
            this.isValidForm = false;
        } else if(this.generatetokendata.org_name == ""){
            this.isValidForm = false;
        } else if (this.generatetokendata.email == ""){
            this.isValidForm = false;
        } else if (this.generatetokendata.plan_duration == ""){
            this.isValidForm = false;
        } else if (this.generatetokendata.plan_type == ""){
            this.isValidForm = false;
        } else if (this.generatetokendata.no_of_users == 0){
            this.isValidForm = false;
        } else if (this.generatetokendata.plan_type == "Named Users" && this.generatetokendata.file_size == 0){
            this.isValidForm = false;
        } else if (this.generatetokendata.plan_type == "conversation" && this.generatetokendata.no_of_conversation == 0){
            this.isValidForm = false;
        }else{
          this.isLoading  = true;
          this.isValidForm = true;
          var data = {"owner": this.generatetokendata.owner,
              "org_name": this.generatetokendata.org_name, 
              "email": this.generatetokendata.email,
              "plan_duration": this.generatetokendata.plan_duration,
              "plan_type": this.generatetokendata.plan_type,
              "no_of_users": this.generatetokendata.no_of_users,
              "file_size": this.generatetokendata.file_size,
              "no_of_conversation": this.generatetokendata.no_of_conversation
              };
          var headers = { "x-api-key": this.apiKey }
          API_Util.URLS.axios
          .post(API_Util.URLS.generatetoken , data, {
            headers,
          })
          .then((response) => {
            var resp = response.data;
            console.log(resp)
            if (resp.status == "Success" ){
                this.response_message = resp.key;
                this.successKeyGenerate = true;
            } else {
              this.response_message = resp.message;
              this.errorMessage = true;
            }
            setTimeout(() => {
              this.isLoading  = false;
              this.clearGenerateTokenData();
              this.generateTokenOrg = false;
              this.userSubscriptionList();
            }, 3000)
          });
        }
    },
    Addsubscription(){
        if(this.subscriptionitem.plan_name == ""){
            this.isValidForm = false;
        } else if (this.subscriptionitem.no_of_users == 0){
            this.isValidForm = false;
        } else if (this.subscriptionitem.file_size == 0){
            this.isValidForm = false;
        } else if (this.subscriptionitem.price == ""){
            this.isValidForm = false;
        } else if (this.subscriptionitem.description == ""){
            this.isValidForm = false;
        } else if (this.subscriptionitem.details == ""){
          this.isValidForm = false;
        } else {
            this.isValidForm = true;
            this.isloading = true;
            var data = this.subscriptionitem;
            const headers = {
            "Content-Type": "application/json",
            "x-api-key": this.apiKey,
            };
            API_Util.URLS.axios
            .post(API_Util.URLS.addsubscription , data, { headers })
            .then((response) => {
                var resp = response.data;
                if (resp.status == "Success" ){
                  this.response_msg = "Subscription Plan Added!"
                  this.response_message = "Your plan has been successfully added.";
                  this.successMessage = true;
                } else {
                  this.response_msg = "Error"
                  this.response_message = resp.message;
                  this.errorMessage = true;
                }
                setTimeout(() => {
                  this.isloading = false;
                  this.clearSubscriptionData();
                  this.addsubscriptions = false;
                  this.getSubscription();
                }, 3000)
            })
            .catch((err) => console.log(err));
        }
    },
    updateSubscription(emittedData) {
      this.subscriptionitem = emittedData;
      console.log(this.subscriptionitem)
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.updatesubscription , this.subscriptionitem , { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.status == "Success" ){
            this.response_msg = "Subscription Plan Updated!"
            this.response_message = "Your plan has been updated successfully.";
            this.successMessage = true;
          } else {
            this.response_msg = "Error";
            this.response_message = resp.message;
            this.errorMessage = true;
          }
          setTimeout(() => {
            this.clearSubscriptionData();
            this.editsubscription = false;
            this.getSubscription();
          }, 3000)
        });
      
    },
    deleteSubscription(){
      console.log(this.selectedSubscription)
      const headers = { "x-api-key": this.apiKey };
      this.selectedSubscription.forEach(function (arrayItem) {
        const data = {
          "id": arrayItem.id,
          "owner_id":localStorage.getItem("owner")
        }
        API_Util.URLS.axios
          .post(API_Util.URLS.deletesubscription , data, {
            headers,
          })
          .then((response)=> {
            var resp = response.data;
            if (resp.status == "Success" ){
              this.response_msg = "Subscription Plan Removed!"
              this.response_message = "Your subscription plan has been successfully deleted.";
              this.successMessage = true;
            } else {
              this.response_msg = "Error"
              this.response_message = resp.message;
              this.errorMessage = true;
            }
          })
      });

        setTimeout(() => {
            this.response_msg = "Subscription Plan Removed!"
            this.response_message = "Your subscription plan has been successfully deleted.";
            this.successMessage = true;
            this.selectedSubscription = [];
            this.deletesubscriptions = false;
            this.getSubscription();
        }, 3000)

    },
    viewSubscriptionDetails(obj) {
      this.detailsubscription = true;
      this.selectedsubscription = obj
    },
    viewUserSubscriptionDetails(obj){
      this.detailusersubscription = true;
      this.selectedusersubscription = obj
    }

  }
};
</script>
<style lang="scss">
.center-container {
  display: flex;
  justify-content: center; 
  align-items: center;    
  height: 10vh;           
  width:100%;          
}
</style>

