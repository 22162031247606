
<template>
  <v-container class="pa-4">
    <v-card class="pa-4" elevation="2">
        <v-row>
            <v-col cols="12">
            <label class="text-subtitle-1 font-weight-bold">
                Enter Your Subscription Token
            </label>
            <v-textarea
                v-model="subscriptiontokendata.activation_key"
                outlined
                dense
                name="input-7-1"
                placeholder="Enter activation key..."
                @input="trimInput"
                @blur="verifytokens()"
                rows="3"
                class="mt-2"
            ></v-textarea>
            <span
                v-if="!subscriptiontokendata.activation_key && !isValidForm"
                class="text-error text-caption"
            >
                Activation Key is required
            </span>
            </v-col>
        </v-row>

        <!-- Decoded Data Section -->
        <v-row  v-if="decode_token_data && Object.keys(decode_token_data).length > 0" class="themeBox pa-4" >
            <v-col cols="12">
                <h3 class="text-h6 font-weight-bold">Subscription Details</h3>
                <v-divider class="mt-2 mb-4"></v-divider>
            </v-col>
            <template v-for="(value, key) in mappedFields">
                <v-col :key="key + '-label'"  cols="6" sm="3">
                    <v-icon small color="primary">mdi-information-outline</v-icon>
                    <span class="ml-2 text-caption font-weight-bold">{{ value.label }}</span>
                </v-col>
                <v-col :key="key + '-label'" cols="6" sm="3">
                    <div class="nobr tertiary1--text text-body-2 font-weight-medium">
                   {{ decode_token_data[key] || 'N/A' }}
                    </div>
                </v-col>
            </template>

            <v-col cols="12">
            <v-checkbox
                v-model="termsAccepted"
                label="I confirm that the above values are correct"
                :error-messages="termsError"
                @change="validateCheckbox"
                class="mt-2"
            ></v-checkbox>
            </v-col>
        </v-row>
    </v-card>
  </v-container>
</template>

<script>
// import API_Util from "../services/util/API_Util.js";
export default {
  name: "tokenOrgCreation",
  props: ['createOrgTokenType','decode_token_data','subscriptiontokendata','termsAccepted'],
  data: () => ({
    emailRules:[
    (value) => !!value || "Email is required",
    (value) => (/.+@.+\..+/.test(value) ? true : "Enter a valid email"),
    ], 
    showCheckboxError: false,
    decode_token_data :{},
    isValidForm: true,
    mappedFields: {
      org_name: { label: "Organization Name" },
      email: { label: "Email" },
      plan_duration: { label: "Plan Duration" },
      plan_type: { label: "Plan Type" },
      no_of_users: { label: "Number of Users" },
      file_size: { label: "Total Storage (GB)" },
      no_of_conversation: { label: "Number of Conversations" }
    }
  }),
  created(){
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerType = localStorage.getItem("ownerType");
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
  },
  methods: {
    trimInput() {
        this.subscriptiontokendata.activation_key = this.subscriptiontokendata.activation_key.trim();
    },
    validateCheckbox() {
      this.$emit("termsAccept", this.termsAccepted);
    },
    verifytokens(){
        this.$emit("verifyTokens", this.subscriptiontokendata.activation_key);
    },
  }
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>