<template>
  <div class="mbl-msform msForm">
    <v-row>   
        <v-col cols="6">
            <label>Plan Name</label>
            <v-text-field
                outlined
                placeholder="Plan Name"
                v-model.trim="subscriptionitem.plan_name"
            ></v-text-field>
            <span v-if="!subscriptionitem.plan_name && !isValidForm" style="color:red;">Plan Name is required</span>  
        </v-col>
        <!-- <v-col cols="12" sm="6">
            <label>Plan Type</label>
            <v-select
            outlined
            placeholder="Select Type"
            v-model="subscriptionitem.plan_type"
            :items="subscriptiontype"
            ></v-select>
            <span v-if="!subscriptionitem.plan_type && !isValidForm" style="color:red;">Type is required</span> 
        </v-col> -->
        <v-col cols="12" sm="6">
            <label>Number of Users</label>
            <v-text-field
              v-model="subscriptionitem.no_of_users"
              type="number"
              outlined
              placeholder="Select Users"
              :min="0"
              step="1"
            />
            <span v-if="!subscriptionitem.no_of_users && !isValidForm" style="color:red;">No of users is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>File Size (GB)</label>
            <v-text-field
              v-model="subscriptionitem.file_size"
              type="number"
              outlined
              placeholder="Select Size"
              :min="0"
              step="1"
            />
            <span v-if="!subscriptionitem.file_size && !isValidForm" style="color:red;">Filesize is required</span> 
        </v-col>
        <v-col cols="6">
            <label>Price</label>
            <v-text-field
                outlined
                placeholder="Price"
                v-model.trim="subscriptionitem.price"
            ></v-text-field>
            <span v-if="!subscriptionitem.price && !isValidForm" style="color:red;">Price is required</span> 
        </v-col>
      <v-col cols="12">
        <label>Description</label>
        <mavon-editor language="en" v-model="subscriptionitem.description" outlined
          name="input-7-1"
          rows="3" />
          <span v-if="!subscriptionitem.description && !isValidForm" style="color:red;">Description is required</span> 
      </v-col>
      <v-col cols="12">
        <label>Details</label>
        <mavon-editor language="en" v-model="subscriptionitem.details" outlined
          name="input-7-1"
          rows="3" />
          <span v-if="!subscriptionitem.details && !isValidForm" style="color:red;">Details is required</span> 
      </v-col> 
    </v-row>
  </div>
</template>

<script>
export default {
  name: "addSubscriptionInfo",
  props: ["subscriptionitem", "isValidForm"],
  data: () => ({
    iconlist: [],    
    // subscriptionname: ["Basic", "Standard", "Business", "Premium"],
    // subscriptiontype: ["1 Month", "3 Month", "6 Month", "1 Year"],
  }),
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>