<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>User Subscription Details</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
         <!-- -----------Add Data Schema------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>User Subscription Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="6">
            <label class="my-4">Owner</label>
            <div>{{rowItem.owner_name}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Org</label>
            <div>{{rowItem.org_name}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Plan Id</label>
            <div>{{rowItem.plan_id}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Plan Name</label>
            <div>{{rowItem.plan_name}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Plan Type</label>
            <div>{{rowItem.plan_type}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Activation Key</label>
            <div>{{rowItem.activation_key}}</div>
          </v-col>
          
        </v-row>
      </div>
    </v-card-text>
    <!-- <v-divider></v-divider> -->
    <v-card-actions>
      <v-spacer></v-spacer>
         <div class="py-5">
      <v-btn class="nocap" @click="closeDetail()" text>
        <span class=""> Cancel</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="detailUserSubscriptions()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          Edit
        </span>
      </v-btn>
         </div>
    </v-card-actions>       
  </v-card>
</template>

<script>

export default {
  name: "detailusersubscription",
  props: ['rowItem',],
 
  data: () => ({
    text: "",
  }),
  methods: {
    detailUserSubscriptions(){
      this.$emit('closeDetail', false)
    },
    closeDetail() {
      this.$emit('detailUserSubscriptions', false)
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>