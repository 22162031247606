import axios from 'axios';                          
const FLASK_CATALOG_URL = process.env.VUE_APP_FLASK_CATALOG_URL;
const FLASK_FLEXI_API_ROOT_URL = process.env.VUE_APP_FLASK_FLEXI_API_ROOT_URL;
const FLASK_ORG_API_ROOT_URL = process.env.VUE_APP_FLASK_ORG_API_ROOT_URL;
const FLASK_BILLING_API_ROOT_URL = process.env.VUE_APP_FLASK_BILLING_API_ROOT_URL;
const ROOT_ADMIN_URL = process.env.VUE_APP_ROOT_ADMIN_URL;
const ROOT_AWS_URL = process.env.VUE_APP_ROOT_AWS_URL;
const ROOT_CORELLIA_URL = process.env.VUE_APP_ROOT_CORELLIA_URL;
const ROOT_MS_SCACLING = process.env.VUE_APP_ROOT_MS_SCACLING;
const FILE_MGMT_URL = process.env.VUE_APP_FLASK_MGMT_API_ROOT_URL
const ROOT_SUBSCRIPTION_URL = process.env.VUE_APP_ROOT_SUBSCRIPTION_URL


const URLS = {                                     
    axios: axios,
    // User Role based Access
    getUserAccessInfo : FLASK_ORG_API_ROOT_URL + 'org/getuseraccessinfo',
    // Microservices
    loginAuth : FLASK_ORG_API_ROOT_URL + "org/v0/loginauth",
    addMicroservice :  ROOT_ADMIN_URL + "admin/v0/updateorgfunction",
    listMicroservice : FLASK_ORG_API_ROOT_URL + "org/v0/getfunctionlist",
    infoMicroservice : ROOT_ADMIN_URL + "admin/v0/getorgfuncinfo",
    addMicroserviceIcon : ROOT_ADMIN_URL + "admin/updatefunctionicion",
    deleteMicroservice : ROOT_ADMIN_URL + "admin/deletefunction",
    deleteMicroPricing : ROOT_ADMIN_URL + "admin/deletefunctionpricing",
    deleteMicroPricingTier : ROOT_ADMIN_URL + "admin/deletefunpricingtier",
    deleteFeatures : ROOT_ADMIN_URL + "admin/deletefunctionfeature",
    cdnImageUpload : "https://cdn.sentient.io/upload.php",
    addOrgAssignedFunctions: ROOT_ADMIN_URL + "admin/orgfunctionassign",
    assignFunctionUpdateAccess: ROOT_ADMIN_URL + "admin/assignfunctionupdateaccess",
    getAssignedOrgFunctionAccess: ROOT_ADMIN_URL + "admin/getownerfunctionaccess",
    datacheck: ROOT_ADMIN_URL + "admin/datacheck",
    checkmsdup: ROOT_ADMIN_URL + "admin/checkmsdup",
    scacleing : ROOT_MS_SCACLING,
    // Owners 
    listOrg :  ROOT_ADMIN_URL + "admin/getmainorglist",
    addOwner : ROOT_ADMIN_URL + "admin/updateownerorg",
    updateOwnerSettings : ROOT_ADMIN_URL + "admin/updateownersetting",
    updatePlandetails: ROOT_ADMIN_URL + "admin/updateplandetails",
    listOwnerSettings : ROOT_ADMIN_URL + "admin/getownersetting",
    infoOwner : ROOT_ADMIN_URL + "admin/getownerorginfo",
    // Functions
    addFunction : ROOT_ADMIN_URL + "admin/v0/updateorgfunction",
    deleteFunction : ROOT_ADMIN_URL + "admin/deletefunction",
    functionUrl : ROOT_ADMIN_URL + "admin/v0/getorgfuncinfo",
    functionList : FLASK_ORG_API_ROOT_URL + "org/v0/getfunctionlist",
    referralList : ROOT_ADMIN_URL + "admin/listreferralcode",
    // Discounts
    discountList : ROOT_ADMIN_URL + "admin/discountlist",
    discountDrops : ROOT_ADMIN_URL + "admin/discountdrops",
    discountAdd : ROOT_ADMIN_URL + "admin/updatediscount",
    deleteDiscount : ROOT_ADMIN_URL + "admin/deletediscount",
    // catalog
    searchtracking : FLASK_CATALOG_URL + 'searchtracking',
    // flexy
    getlblbyscr: FLASK_FLEXI_API_ROOT_URL + 'flexi/getlblbyscr',
    flexyListValue : FLASK_FLEXI_API_ROOT_URL + "flexi/getlist",
    flexyLangValue : FLASK_FLEXI_API_ROOT_URL + "flexi/getlanguagelist",
    flexyLangList : FLASK_FLEXI_API_ROOT_URL + "flexi/getorglanginfo",
    flexyDelete : FLASK_FLEXI_API_ROOT_URL + "flexi/deleteorglang",
    flexyAdd : FLASK_FLEXI_API_ROOT_URL + "flexi/updatelanguages",

    flexylabelList: FLASK_FLEXI_API_ROOT_URL + "flexi/getlbllist",
    flexyLabelAdd: FLASK_FLEXI_API_ROOT_URL + "flexi/updatelbl",
    deleteFlexyLabel: FLASK_FLEXI_API_ROOT_URL + "flexi/deletelbl",
    deleteTransLabel: FLASK_FLEXI_API_ROOT_URL + "flexi/deletelbltrans",

    flexyerrorList: FLASK_FLEXI_API_ROOT_URL + "flexi/geterrorlist",
    flexyErrorAdd: FLASK_FLEXI_API_ROOT_URL + "flexi/updateerror",
    deleteFlexyError: FLASK_FLEXI_API_ROOT_URL + "flexi/deleteerror",
    deleteTransError: FLASK_FLEXI_API_ROOT_URL + "flexi/deleteerrortrans",

    flexycodeList: FLASK_FLEXI_API_ROOT_URL + "flexi/getflexicodelist",
    flexyCodeListAdd: FLASK_FLEXI_API_ROOT_URL + "flexi/v0/updateflexicode",
    deleteFlexyCodeList: FLASK_FLEXI_API_ROOT_URL + "flexi/deletecode",

    flexyvalueList: FLASK_FLEXI_API_ROOT_URL + "flexi/getvallist",
    flexyValueAdd: FLASK_FLEXI_API_ROOT_URL + "flexi/updatecodeval",
    deleteTransValue: FLASK_FLEXI_API_ROOT_URL + "flexi/deletecodevaltrans",
    flexyValInfo: FLASK_FLEXI_API_ROOT_URL + "flexi/getvalinfo",
    deleteFlexyValue: FLASK_FLEXI_API_ROOT_URL + "flexi/deletecodeval",

    flexysubvalList: FLASK_FLEXI_API_ROOT_URL + "flexi/getvalsublist",
    flexysubvalAdd: FLASK_FLEXI_API_ROOT_URL + "flexi/updatecodevalsub",
    flexysubvalDelete: FLASK_FLEXI_API_ROOT_URL + "flexi/deletecodeval",
    //  Promocodes
    addPromocode : ROOT_ADMIN_URL + "admin/updatepromocode",
    listPromocode : ROOT_ADMIN_URL + "admin/v0/listpromocode",
    deletePromocode : ROOT_ADMIN_URL + "admin/deletepromocode",
    // Demoapps
    addDemoapps : ROOT_ADMIN_URL + "admin/v0/updatemicroservice",
    listDemoapps : ROOT_ADMIN_URL + "admin/getmicroservicelist",
    infoDemoapps : ROOT_ADMIN_URL + "admin/v0/getmicroserviceinfo",
    deleteDemoapps : ROOT_ADMIN_URL + "admin/deletemicroservice",
    deleteDemoappsFunction : ROOT_ADMIN_URL + "admin/deletefuncinmicroserv",
    updatemicroservicemultiimage : ROOT_ADMIN_URL + "admin/updatemicroservicemultiimage",
    updatemicroserviceicon : ROOT_ADMIN_URL + "admin/updatemicroserviceicon",
    deletemicroservicemultiimage : ROOT_ADMIN_URL + "admin/deletemicroservicemultiimage",
    // Predefined Groups
    predefinedList : ROOT_ADMIN_URL + "admin/v0/listpredefinedgroup",
    predefinedAdd : ROOT_ADMIN_URL + "admin/v0/updatepredefinedgroup",
    funcListing : FLASK_ORG_API_ROOT_URL + "org/v0/getorgassignfunctions",
    assignPreFun : ROOT_ADMIN_URL + "admin/assignpredefinedgroupfuncs",
    deletePredefined : ROOT_ADMIN_URL + "admin/deletepredefinedgroup",
    deleteAssignFunc : ROOT_ADMIN_URL + "admin/deletepredefinedgroupfuncs",

    //ORG-admin
    removefunctionfromorg: FLASK_ORG_API_ROOT_URL + 'org/removefunctionfromorg',
    assignfunction: FLASK_ORG_API_ROOT_URL + 'org/assignfunction',
    updateorginfo: FLASK_ORG_API_ROOT_URL + 'org/v0/addorg',
    updateorggroup: FLASK_ORG_API_ROOT_URL + 'org/v0/updateorggroup',
    removefunctionfromgroup: FLASK_ORG_API_ROOT_URL + 'org/v0/removefunctionfromgroup',
    addfunctiontogroup: FLASK_ORG_API_ROOT_URL + 'org/v0/addfunctiontogroup',
    getgroupinfo: FLASK_ORG_API_ROOT_URL + 'org/v0/getgroupinfo',
    getorggrouplist: FLASK_ORG_API_ROOT_URL + 'org/v0/getorggrouplist',
    edituserinfo : FLASK_ORG_API_ROOT_URL + 'org/v0/edituser',
    refreshapikey: FLASK_ORG_API_ROOT_URL + 'org/v0/refreshapikey',
    deletegroup: FLASK_ORG_API_ROOT_URL + 'org/v0/deletegroup',
    activateusers: FLASK_ORG_API_ROOT_URL + 'org/v0/activateusers',
    deleteuser: FLASK_ORG_API_ROOT_URL + 'org/v0/deleteuser',
    resetpasswordemail: FLASK_ORG_API_ROOT_URL + 'org/resetpasswordemail',
    activateorg: FLASK_ORG_API_ROOT_URL + 'org/activateorg',
    getorginfo: FLASK_ORG_API_ROOT_URL  + 'org/v0/getorginfo',
    deleteorg: FLASK_ORG_API_ROOT_URL + 'org/deleteorg',
    editorginfo : FLASK_ORG_API_ROOT_URL  + 'org/v0/editorg',
    removeuserfromgroup: FLASK_ORG_API_ROOT_URL + 'org/v0/removeuserfromgroup',
    getdeleteduserlist: FLASK_ORG_API_ROOT_URL + 'org/v0/getdeleteduserlist',
    getorgemail: FLASK_ORG_API_ROOT_URL + 'org/getorgemail',
    getuserlist: FLASK_ORG_API_ROOT_URL + 'org/v0/getuserlist',
    checkuserlimit : FLASK_ORG_API_ROOT_URL + 'org/checkuserlimit',
    listpredefinedgroup: FLASK_ORG_API_ROOT_URL + 'org/listpredefinedgroup',
    addusertogroup: FLASK_ORG_API_ROOT_URL + 'org/v0/addusertogroup',
    removeuserfromfunction: FLASK_ORG_API_ROOT_URL + 'org/v0/removeuserfromfunction',
    addusertofunction: FLASK_ORG_API_ROOT_URL + 'org/v0/addusertofunction',
    getorgassignfunctions: FLASK_ORG_API_ROOT_URL + 'org/v0/getorgassignfunctions',
    getfunctionlist: FLASK_ORG_API_ROOT_URL + 'org/v0/getfunctionlist',
    getuserinfo: FLASK_ORG_API_ROOT_URL + 'org/v0/getuserinfo',
    updateOrg: FLASK_ORG_API_ROOT_URL + 'org/v0/addorg',
    getOrgList: FLASK_ORG_API_ROOT_URL + 'org/getorglist',
    getOrgDetails: FLASK_ORG_API_ROOT_URL + 'org/v0/getorgdetails',
    getDeletedOrgList: FLASK_ORG_API_ROOT_URL + 'org/getdeletedorglist',
    updateuserinfo: FLASK_ORG_API_ROOT_URL + 'org/v0/adduser',
    accountlist : FLASK_ORG_API_ROOT_URL + 'org/accountlist',
    promoreferral : FLASK_ORG_API_ROOT_URL + 'org/promoreferral',
    getnotifications: FLASK_ORG_API_ROOT_URL + 'getnotifications',
    insertnotifications: FLASK_ORG_API_ROOT_URL  + 'insertnotifications',
    updatenotifications: FLASK_ORG_API_ROOT_URL  + 'updatenotifications',
    deletenotifications: FLASK_ORG_API_ROOT_URL + 'deletenotifications',
    usernotifications: FLASK_ORG_API_ROOT_URL  + 'usernotifications',
    

    getsubscription: FLASK_ORG_API_ROOT_URL + 'getsubscription',
    addsubscription: FLASK_ORG_API_ROOT_URL  + 'addsubscription',
    updatesubscription: FLASK_ORG_API_ROOT_URL  + 'updatesubscription',
    deletesubscription: FLASK_ORG_API_ROOT_URL + 'deletesubscription',
    getusersubscription: FLASK_ORG_API_ROOT_URL + 'getusersubscription',
    generatetoken: ROOT_SUBSCRIPTION_URL  + 'generatetoken',
    verifytoken: ROOT_SUBSCRIPTION_URL  + 'verifytoken',
    updateusersubscription: FLASK_ORG_API_ROOT_URL  + 'updateusersubscription',
    deleteusersubscription: FLASK_ORG_API_ROOT_URL + 'deleteusersubscription',
    usersubscriptionlist: FLASK_ORG_API_ROOT_URL + 'usersubscriptionlist',
    verifytokenrequest: ROOT_SUBSCRIPTION_URL  + 'verifytokenrequest',
    insertsubscriptionrequest: FLASK_ORG_API_ROOT_URL + 'insertsubscriptionrequest',
 
    //AWS api:
    getsetting: ROOT_AWS_URL + 'da/getsetting',
    setting: ROOT_AWS_URL + 'da/setting',
    updatecreditexpiry: FLASK_BILLING_API_ROOT_URL + 'billing/updatecreditexpiry',
    listcreditexpiry: FLASK_BILLING_API_ROOT_URL + 'billing/listcreditexpiry',
    address: FLASK_BILLING_API_ROOT_URL + 'billing/address',
    requesttopup: FLASK_BILLING_API_ROOT_URL + 'billing/requesttopup',
    spendingaccountinfo: FLASK_BILLING_API_ROOT_URL + 'billing/spendingaccountinfo',
    paypalentry: FLASK_BILLING_API_ROOT_URL + 'billing/paypalentry',
    
    // corellia aapi
    register : ROOT_CORELLIA_URL+'register/getpredictions',
    viewdetails : ROOT_CORELLIA_URL+'viewdetails/getpredictions',
    updatedetails : ROOT_CORELLIA_URL+'updatedetails/getpredictions',
    getregmicrolist : ROOT_CORELLIA_URL+'getregmicrolist/getpredictions',
    deleteregmicrolist : ROOT_CORELLIA_URL+'deleteregmicrolist/getpredictions',
    deploy : ROOT_CORELLIA_URL+'deploy/getpredictions',
    

    llmoutagelist : FILE_MGMT_URL+'llm-outage-list',
    llmaddoutage : FILE_MGMT_URL+'add-llm-outage',
    llmupdateoutage : FILE_MGMT_URL+'update-llm-outage',
    llmdeleteoutage: FILE_MGMT_URL+'delete-llm-outage',
    chatpropmt : FILE_MGMT_URL+'chat-prompt',
    updateprompt : FILE_MGMT_URL+'update-chat-prompt',
}
export default {
    URLS
};
