<template>

<div class="d-flex flex-column" style="height: 100%">
  <!-- ** Originally from AddOrg.vue, changed to a more descriptive name -->
  <div class="s-css-reset flex-fill mt-0">
    <v-breadcrumbs :items="items" v-if="ownerDrop">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
  <v-card elevation="2" class="white py-6 maincontent">
      <h2>
        {{ labels.TITLE }}
      </h2>

      <!-- ---------------------------------- -->
      <!-- Manage organisations button groups -->
      <!-- ---------------------------------- -->
      <v-row class="justify-space-between manageOrg">
        <!--  -->
        <v-btn
          color="tertiary1"
          v-if="ownerType=='PM'"
          dark
          class="my-4 nocap addOrg-btn"
          @click="toggleDialog('createOrganisationWindow', true)"
        >
          <v-icon>mdi-bank-plus</v-icon>
          {{ labels.SUB_TITLE }}
        </v-btn>
        <v-btn
          color="tertiary1"
          dark
          v-if="ownerType=='TS'"
          class="my-4 nocap addOrg-btn"
          @click="claerdataorg()"
        >
          <v-icon>mdi-bank-plus</v-icon>
          {{ labels.SUB_TITLE }}
        </v-btn>

        <v-text-field  v-if="!showDeletedOrgs"
              class="search-bar sen-bg-white"
              v-model="searchText"
              clearable
               @click:clear="resetSearch(true)"
              solo
              hide-details
              append-icon="mdi-magnify"
              :label="'Search Organisations'"              
            v-on:keyup="findText()"
            ></v-text-field>

<v-text-field  v-if="showDeletedOrgs"
              class="search-bar sen-bg-white"
              v-model="delsearchText"
              clearable
              @click:clear="resetSearch(false)"
              solo
              hide-details
              append-icon="mdi-magnify"
              :label="'Search Organisations'"              
            v-on:keyup="findText()"
            ></v-text-field>
        <Dialog
          v-if="showDialog.show"
          dialog-name="createOrganisationWindow"
          :dialog-title="labels.SUB_TITLE"
          max-width="720px"
        >
          <CreateOrganisation class="px-4" />
        </Dialog>
        <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="createOrgTokenType"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-overlay absolute v-if="isLoading" opacity="0" z-index="9999">
                    <v-container fill-height>
                      <v-row justify="center" align="center">
                        <v-progress-circular
                          indeterminate
                          size="40" 
                          color="primary"
                          width="4"
                          style="stroke-dasharray: 4, 4"
                        ></v-progress-circular>
                      </v-row>
                    </v-container>
                  </v-overlay>
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">Create Organization And Assign Token</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearSubscriptionObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div>
                      <tokenOrgCreation
                      :createOrgTokenType="createOrgTokenType"
                      @termsAccept="termsAccept"
                      :subscriptiontokendata ="subscriptiontokendata"
                      :decode_token_data="decode_token_data"
                      @verifyTokens="verifyTokens"
                      />
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearSubscriptionObj()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      :disabled="!termsAccepted"
                      color="primary"
                      @click="verifyTokensRequest()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successMessage"
              persistent
              max-width="500"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 300px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div v-if="response_msg.length" class="mt-4">{{response_msg}}</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{response_message}}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successMessage = false">Okay </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        <!-------------------------------------start delete popup--------------------------------->
        <v-row justify="center">
          <v-dialog
            class="msPop"
            style="box-shadow: none; background-color: transparent"
            v-model="errorMessage"
            persistent
            max-width="388"
            content-class="vDialog"
          >
            <v-card
              class="d-flex flex-column pa-0 align-center justify-center"
              flat
              style="background-color: transparent"
            >
              <v-card-title
                class="text-h5 text-bold d-flex text-center pa-0"
                style="margin-bottom: -19px"
              >
                <v-img
                  style="z-index: 1"
                  alt="error icon"
                  class="d-flex align-center justify-center"
                  src="@/assets/error-mark1.svg"
                  contain
                  max-width="192"
                ></v-img>
              </v-card-title>
              <v-card-text
                class="text-center white rounded-lg pa-4"
                style="height: 220px"
              >
                <v-card-title class="text-h5 text-bold d-flex justify-center text-red">
                  <div v-if="response_msg.length" class="mt-4">{{response_msg}}</div>
                </v-card-title>
                <p v-if="response_message.length">{{response_message}}</p>
                <v-card-actions>
                  <div class="text-center mt-4">
                    <v-btn class="nocap" color="red darken-2" @click="errorMessage = false">
                      Okay
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
        <!--  -->
        <!-- Call action from organisationService.js directly -->
        <v-btn
          v-if="orgsToReactivate[0]"
          class="my-4 nocap showDltOrg"
          dark
          color="tertiary2"
          @click="toggleConfirm('reactivateSelectedorgs')"
        >
          <v-icon>mdi-account-reactivate</v-icon>
          {{ labels.SUB_TITLE_RE_ACT }}
        </v-btn>
        <!--  -->
        <v-btn
          text
          class="nocap my-4 showDltOrg"
          color="red"
          v-if="!showDeletedOrgs"
          @click="toggleShowDeletedOrgs"
        >
          <v-icon>mdi-delete-clock-outline</v-icon>
          {{ labels.SUB_TITLE_DEL }}
        </v-btn>
        <v-btn
          text
          class="nocap my-4"
          color="success"
          v-else
          @click="toggleShowDeletedOrgs"
        >
          <v-icon>mdi-office-building</v-icon>
          {{ labels.SUB_TITLE_ACT }}
        </v-btn>
      </v-row>
      <!-- -ABOVE- Manage organisations button groups -->
      <!-- ========================================== -->

      <!-- vieOrgDetails function will route to organisation detial page -->
      <!-- Currently using a dummy page for front-end testing -->
      <!-- Actived Organisations -->
      <v-data-table
        :mobile-breakpoint="0"
        :headers="tableHeaders"
        :items="activeOrgs"  
        class="elevation-0 s-table-beige manageTbl s-table mt-6"
        style="cursor: pointer"
        @click:row="viewOrgDetails"
        v-if="!showDeletedOrgs"
      >
      </v-data-table>
      <!-- Deleted organisations -->
      <v-data-table
        v-else
        :mobile-breakpoint="0"
        :headers="tableHeaders"
        :items="deletedOrgs"      
        class="elevation-0 s-table-beige s-table mt-6"
        style="cursor: pointer"
        show-select
        v-model="orgsToReactivate"
      >
      </v-data-table>
    </v-card>

    <!-- Activate Organisation Confirmation -->
    <!-- ----------------------------------- -->
        <ConfirmBox
            box-name="reactivateSelectedorgs"
            :on-confirm-value="orgsToReactivate"
            :box-title="labels.ORG_REACT_CONFIRM"
            :box-content="labels.ORG_REACT_AREYOU"
            box-class="warning-dialog"
            :confirm-btn-text="labels.OD_RE_ACT"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-account-reactivate"
        >
        </ConfirmBox>
    <!-- -* ABOVE *- Activate Organisation Confirmation -->

    
    <v-overlay :value="loader" z-index="6" color="#cececea6">
      <!-- <v-img
        alt="sentient logo"
        class="shrink mt-1"
        :contain="true"
        src="@/assets/loader.gif"
        width="200"
      ></v-img> -->
      <loaderimg v-if="loader"/>
    </v-overlay>
  </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";
// Services
import {
  manageOrgsService,
  organisationService,
} from "../../services/organisationService";
// Components
import Dialog from "../ui/Dialog";
import ConfirmBox from "../ui/ConfirmBox";
import CreateOrganisation from "./CreateOrganisation";
import tokenOrgCreation from "../tokenOrgCreation";
import Footer from "./../Footer.vue";
import loaderimg from "../loaderimg.vue";
import headline from "../headline.vue";
export default defineComponent({
  components: { Dialog, CreateOrganisation, tokenOrgCreation, Footer ,ConfirmBox, loaderimg,headline,},
  // data: () => ({
  //   confirmDialog: true,
  // }),
  selectedOwner: "",
  ownerDesc: "",
  methods: {
    changeOwner(obj) {
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
    },
  },
  created: function () {
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerType = sessionObj.session.ownerType;
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
  },
  setup(_, ctx) {
    //SESSION OBJECTS
    const session = ctx.root.$session,
    u_session=session.get("user_session").session;
    const ownerDrop = ref(JSON.parse(localStorage.getItem('ownerDrop')));
    const items = [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Organisations",
        disabled: true,
        href: "",
      },
    ];
 
    items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    window.document.title = "Platform-admin";
    if(u_session.orgType!='main'){
      router.push("/orgdetails");////
    }
    // Use ctx to bring router via composition-api
    const { allOrganisations, reactivateOrgs, requestVerifyToken, verifyToken, verifytokensrequest, verifytokens } = manageOrgsService(),
      {
        dialog,
        toggleDialog,
        showDialog,
        confirmBoxs,
        toggleConfirm,
        getActiveOrgList,
        getdeleteOrgList,
        getOrganisationDetails,
        getFlexi,
        organisation,
      } = organisationService(),
      router = ctx.root.$router,
      labels = ref({}),
      activeOrgs = ref([]),
      deletedOrgs = ref([]),
      showDeletedOrgs = ref(false),
      confirmDialog = ref(false),
      orgsToReactivate = ref([]),
      loader = ref(false),
      searchText=ref(""),
      response_msg = ref(""),
      successMessage =ref(false),
      errorMessage = ref(false),
      response_message =ref(""),
      isLoading= ref(false),
      createOrgTokenType = ref(false),
      decode_token_data = ref({}),
      subscriptiontokendata = ref({}),
      tokenrequestresponse = ref({}),
      termsAccepted = ref(false),
      delsearchText=ref("");
    // get org details
    const getOrg = async () => {
      //active org list binding
      await getActiveOrgList();
      loader.value = true;
      activeOrgs.value = allOrganisations.active_list;
      // in active org list binding
      await getdeleteOrgList();
      deletedOrgs.value = allOrganisations.inactive_list;
      loader.value = false;
    };
    getOrg();
    const verifyTokensRequest = async () => {
      isLoading.value = true;
      await verifytokensrequest(subscriptiontokendata.value.activation_key);

      tokenrequestresponse.value = requestVerifyToken.value
      if ( tokenrequestresponse.value.status == "Success"){
        response_msg.value = "Organization successfully created!"
        response_message.value= "Organization successfully created and Subscription Token assigned!";
        successMessage.value = true;
      } else {
        response_msg.value = "Error"
        response_message.value = tokenrequestresponse.value.message;
        errorMessage.value= true;
      }
      setTimeout(() => {
        isLoading.value = false;
        clearTokenData();
        createOrgTokenType.value = false;
        getOrg();
      }, 3000)
    };
    const verifyTokens = async (activation_key) => {
      await verifytokens(activation_key);
      decode_token_data.value = verifyToken.value.results
      console.log(decode_token_data.value)
    };
    const clearTokenData = async () => {
      decode_token_data.value = {},
      subscriptiontokendata.value = {}
    };
    const claerdataorg = async () => {
      createOrgTokenType.value = true;
      decode_token_data.value = "";
      subscriptiontokendata.value = {};
      termsAccepted.value = false;
    };
    const getreativeOrgList = async () => {
      confirmDialog.value=true;
      getOrg();
    };

const findText = () =>{    
  if(!showDeletedOrgs.value){
 activeOrgs.value= allOrganisations.active_list.filter(list => list.name.toUpperCase().indexOf(searchText.value.toUpperCase()) !=-1)
  }
   else{
    deletedOrgs.value= allOrganisations.inactive_list.filter(list => list.name.toUpperCase().indexOf(delsearchText.value.toUpperCase()) !=-1)
   }
};
const resetSearch = (searchType)=>{
  if(searchType==true){
    activeOrgs.value=allOrganisations.active_list;
    searchText.value='';
  }
  else{
    delsearchText.value='';
     deletedOrgs.value= allOrganisations.inactive_list;
  }

  
}
    const viewOrgDetails = (org) => {
      localStorage.setItem("orgId", org.id);
      getOrganisationDetails();
      router.push("/orgdetails");
    };

    const toggleShowDeletedOrgs = () => {
      // Init orgsToReactivate
      orgsToReactivate.value = [];
      showDeletedOrgs.value = !showDeletedOrgs.value;
    };
    const clearSubscriptionObj =()=>{
      createOrgTokenType.value = false;
      decode_token_data.value = "";
      subscriptiontokendata.value = {};
      termsAccepted.value = false;
    };
    const termsAccept =(terms)=>{
      termsAccepted.value = terms
    };

    const tableHeaders = ref([]);
    const getFlexiLabels = async () => {
      // await getFlexi("ORG", session.get("lang"));
      await getFlexi("ORG", localStorage.getItem("lang"));
      labels.value = organisation.labels;
      tableHeaders.value = [
        { text: labels.value.NAME, align: "center", value: "name" },
        { text: labels.value.DESC, align: "center", value: "desc" },
        // { text: 'Created By', align: 'center', value: 'orgCreatedBy' },
        {
          text: labels.value.CREATE_DATE,
          align: "center",
          value: "dateCreated",
        },
        { text: labels.value.STATUS, align: "center", value: "status" },
      ];
    };
    getFlexiLabels();
    return {
      tableHeaders,
      activeOrgs,
      deletedOrgs,
      loader,
      viewOrgDetails,
      showDeletedOrgs,
      toggleShowDeletedOrgs,
      orgsToReactivate,
      reactivateOrgs,
      dialog,
      toggleDialog,
      showDialog,
      getActiveOrgList,
      getdeleteOrgList,
      getreativeOrgList,
      getOrganisationDetails,
      getFlexiLabels,
      organisation,
      labels,
      u_session,
      confirmBoxs,
      toggleConfirm,
      searchText,
      delsearchText,
      findText,
      resetSearch,
      ownerDrop,
      items,
      createOrgTokenType,
      clearSubscriptionObj,
      verifyTokensRequest,
      verifyTokens,
      subscriptiontokendata,
      decode_token_data,
      tokenrequestresponse,
      response_msg,
      successMessage,
      errorMessage,
      response_message,
      isLoading,
      clearTokenData,
      termsAccepted,
      termsAccept,
      claerdataorg
    };
  },
});
</script>

<style lang="scss" scoped>
.search-bar {
    max-width: 460px !important;
    margin: 0 auto !important;
}

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .owner-tlt {
  //@at-root-top: 0px;
  margin-top: 0px;
}
.maincontent {
  margin: 0 !important;
}
.addOrg-btn {
  width: 100%;
}
.showDltOrg {
  padding-left: 0 !important;
}
} 
</style>