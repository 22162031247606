<template>
  <div class="mbl-msform msForm">
    <v-row>   
        <v-col cols="12" sm="6">
            <label>Owner</label>
            <v-select
            outlined
            placeholder="Select Owner"
            v-model="usersubscriptionitem.owner_name"
            item-text="name"
            item-value="id"
            
            ></v-select>
            <span v-if="!usersubscriptionitem.owner && !isValidForm" style="color:red;">Owner is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Org</label>
            <v-select
            outlined
            placeholder="Select Org"
            v-model="usersubscriptionitem.org"
            :items="activeorglist"
            item-text="name"
            item-value="id"
            ></v-select>
            <span v-if="!usersubscriptionitem.org && !isValidForm" style="color:red;">Org is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Plan Id</label>
            <v-select
            outlined
            v-model="usersubscriptionitem.plan_id"
            type="number"
            placeholder="Select Id"
            :min="0"
            step="1"
            ></v-select>
            <span v-if="!usersubscriptionitem.plan_id && !isValidForm" style="color:red;">Plan_id is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Plan Name</label>
            <v-select
            outlined
            placeholder="Select Name"
            v-model="usersubscriptionitem.plan_name"
            :items="subscriptionname"
            ></v-select>
            <span v-if="!usersubscriptionitem.plan_name && !isValidForm" style="color:red;">Name is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Plan Type</label>
            <v-select
            outlined
            placeholder="Select Type"
            v-model="usersubscriptionitem.plan_type"
            :items="subscriptiontype"
            ></v-select>
            <span v-if="!usersubscriptionitem.plan_type && !isValidForm" style="color:red;">Type is required</span> 
        </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "addSubscriptionInfo",
  props: ["usersubscriptionitem", 'activeorglist', 'ownerList', 'response_status', 'response_msg', "isValidForm"],
  data: () => ({
    iconlist: [],    
    subscriptionname: ["basic", "standard", "business", "premium"],
    subscriptiontype: ["1 month", "3 month", "6 month", "1 year"],
  }),
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>