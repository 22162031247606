
<template>
  <div class="mbl-msform msForm">
    <v-row> 
        <v-col cols="12" sm="6">
            <label>Owner</label>
            <v-select
            outlined
            placeholder="Select Owner"
            v-model="generatetokendata.owner"
            :items="ownerList"
            item-text="ownerName"
            item-value="id"
            ></v-select>
            <span v-if="!generatetokendata.owner && !isValidForm" style="color:red;">Owner is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Organisation Name</label>
            <v-text-field
            outlined
            placeholder="Org Name"
            v-model="generatetokendata.org_name"
            ></v-text-field>
            <span v-if="!generatetokendata.org_name && !isValidForm" style="color:red;">Org Name is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
        <label>Email</label>
        <v-text-field
          v-model="generatetokendata.email"
          ref="emailField"
          placeholder="Email"
          outlined
          :rules="emailRules"
          required
          @blur="validateEmail"
        ></v-text-field>
        <span v-if="!generatetokendata.email && !isValidForm" style="color:red;">Email is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Plan Duration</label>
            <v-select
            outlined
            placeholder="Select plan"
            v-model="generatetokendata.plan_duration"
            :items="planduration"
            ></v-select>
            <span v-if="!generatetokendata.plan_duration && !isValidForm" style="color:red;">Plan duration is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Plan Type</label>
            <v-select
            outlined
            placeholder="Select plan"
            v-model="generatetokendata.plan_type"
            :items="plantype"
            ></v-select>
            <span v-if="!generatetokendata.plan_type && !isValidForm" style="color:red;">Plan type is required</span> 
        </v-col>
        <v-col cols="12" sm="6">
            <label>Number of Users</label>
            <v-text-field
              v-model="generatetokendata.no_of_users"
              type="number"
              outlined
              placeholder="Select Users"
              :min="0"
              step="1"
            />
            <span v-if="!generatetokendata.no_of_users && !isValidForm" style="color:red;">No of users is required</span> 
        </v-col>
        <v-col cols="12" sm="12" v-if="generatetokendata.plan_type=='Named Users'">
            <label>Total Storage Requirements (GB)</label>
            <v-text-field
              v-model="generatetokendata.file_size"
              type="number"
              outlined
              placeholder="Select Size"
              :min="0"
              step="1"
            />
            <span v-if="!generatetokendata.file_size && !isValidForm" style="color:red;">Filesize is required</span> 
        </v-col>
        <v-col cols="12" sm="12" v-if="generatetokendata.plan_type=='Conversation'">
            <label>Number of Conversation</label>
            <v-text-field
              v-model="generatetokendata.no_of_conversation"
              type="number"
              outlined
              placeholder="Select Size"
              :min="0"
              step="1"
            />
            <span v-if="!generatetokendata.no_of_conversation && !isValidForm" style="color:red;">Filesize is required</span> 
        </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "generatetoken",
  props: ['generatetokendata','ownerList', 'isValidForm','planduration'],
  data: () => ({
    emailRules:[
      v => !!v || 'E-mail is required',
      v => /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(v) || 'E-mail must be valid'
    ],
    plantype: ["Named Users", "Conversation"],
    // planduration: ["1 Year", "1 Month(Trial)"],
    
    termsAccepted : false,
  }),
  methods: {
    validateEmail() {
      if (this.$refs.emailField) {
        this.$refs.emailField.validate();
      }
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>