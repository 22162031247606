<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>Subscription Details</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
         <!-- -----------Add Data Schema------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>Subscription Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="4">
            <label class="my-4">Plan Name</label>
            <div>{{rowItem.plan_name}}</div>
          </v-col>
          <v-col cols="12" sm="4">
            <label class="my-4">No of Users</label>
            <div>{{rowItem.no_of_users}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">File Size</label>
            <div>{{rowItem.file_size}}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <label class="my-4">Price</label>
            <div>{{rowItem.price}}</div>
          </v-col>
          <v-col cols="12" sm="12">
            <label class="my-4">Description</label>
            <div>{{rowItem.description}}</div>
          </v-col>
          <v-col cols="12" sm="12">
            <label class="my-4">Details</label>
            <div>{{rowItem.details}}</div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <!-- <v-divider></v-divider> -->
    <v-card-actions>
      <v-spacer></v-spacer>
         <div class="py-5">
      <v-btn class="nocap" @click="closeDetail()" text>
        <span class=""> Cancel</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="details()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          Edit
        </span>
      </v-btn>
         </div>
    </v-card-actions>       
  </v-card>
</template>

<script>

export default {
  name: "detailsubscription",
  props: ['rowItem',],
 
  data: () => ({
    text: "",
  }),
  methods: {
    details(){
      this.$emit('closeDetail', false)
    },
    closeDetail() {
      this.$emit('details', false)
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>